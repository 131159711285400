<template>
  <!-- 录入信息 -->
  <div class="addInformation page-info-content">
    <!-- 这个是信息未补入时候填写的数据 -->
    <div v-if="ruleForm&&ruleForm.cmpState!=='-1'&&ruleForm.cmpState!=='99'" class="informationNotAdded">
      <!-- 消息提示 -->
      <div class="informationFlow">
        <p class="tip">
          <img src="@/assets/image/deng.png" alt="deng">
          温馨提示：完善企业信息后，您可以使用更多功能
        </p>
        <el-steps :active="stepsActive" align-center>
          <el-step title="完善信息" />
          <el-step title="提交审核" />
          <el-step title="完成审核" />
        </el-steps>
      </div>
      <!-- 要填写的东西 -->
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-position="left"
        label-width="140px"
        class="ruleForm form-item-row"
      >
        <h3 class="page-subtitle-shade">
          基本信息
        </h3>
        <el-form-item label="公司编号" prop="cmpCode">
          <el-input v-model="ruleForm.cmpCode" size="small" disabled placeholder="系统自动生成" />
        </el-form-item>
        <el-form-item label="公司名称" prop="cmpName">
          <el-input v-model="ruleForm.cmpName" size="small" placeholder="请输入公司名称" />
        </el-form-item>
        <el-form-item label="公司简称" prop="cmpShortName">
          <el-input v-model="ruleForm.cmpShortName" size="small" placeholder="请输入公司简称" />
        </el-form-item>
        <!-- <el-form-item label="授权系统" prop="cmpRoleList">
          <el-select v-model="ruleForm.cmpRoleList" size="small" multiple placeholder="请选择授权系统">
            <el-option
              v-for="item in $store.getters.getDictionaryItem('SYS_TYPE')"
              :key="item.dictId"
              :disabled="item.dictId === '00'"
              :label="item.dictName"
              :value="item.dictId"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="注册资本(万元)" prop="cmpRegFund">
          <el-input-number
            v-model="ruleForm.cmpRegFund"
            :precision="2"
            :controls="false"
            size="small"
            placeholder="请输入注册资本(万元)"
          />
        </el-form-item>
        <el-form-item label="注册地址" prop="cmpAddress">
          <el-input v-model="ruleForm.cmpAddress" size="small" placeholder="请输入注册地址" />
        </el-form-item>
        <h3 class="page-subtitle-shade">
          营业执照信息
        </h3>
        <el-form-item label="统一社会信用代码" prop="cmpUnicode">
          <el-input v-model="ruleForm.cmpUnicode" size="small" placeholder="请输入统一社会信用代码" />
        </el-form-item>
        <el-form-item label="成立日期" prop="cmpDate">
          <el-date-picker
            v-model="ruleForm.cmpDate"
            value-format="yyyy-MM-dd"
            size="small"
            type="date"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="营业期限截止日">
          <el-date-picker
            v-model="ruleForm.cmpCloseDate"
            value-format="yyyy-MM-dd"
            size="small"
            type="date"
            placeholder="不选择日期则默认为长期"
          />
        </el-form-item>
        <el-form-item label="登记机关" prop="cmpRegOrg">
          <el-input v-model="ruleForm.cmpRegOrg" size="small" placeholder="请输入登记机关" />
        </el-form-item>
        <el-form-item label="公司类型" prop="cmpType">
          <el-select v-model="ruleForm.cmpType" size="small" placeholder="请选择公司类型">
            <el-option label="国企" value="国企" />
            <el-option label="民营" value="民营" />
            <el-option label="股份制" value="股份制" />
            <el-option label="上市" value="上市" />
          </el-select>
        </el-form-item>
        <el-form-item class="overallWidth" label="营业执照电子版" prop="businessLicense">
          <span class="tip">提示：请上传多证合一营业执照，照片或扫描件；支持JPG、JPEG、PNG格式，大小不超过10M</span>
          <ImageUpload :explain="'上传营业执照'" :can-only-pictures="true" :edit-img-url="ruleForm.businessLicense" @getImgUrl="getImgUrl1" />
        </el-form-item>
        <!-- 法人信息 -->
        <h3 class="page-subtitle-shade">
          法人信息
        </h3>
        <el-form-item label="法人姓名" prop="leadName">
          <el-input v-model="ruleForm.leadName" size="small" placeholder="请输入法人姓名" />
        </el-form-item>
        <el-form-item label="身份证号码" prop="leadPaperid">
          <el-input v-model="ruleForm.leadPaperid" size="small" placeholder="请输入管理员身份证号码" />
        </el-form-item>
        <el-form-item label="身份证开始日期" prop="leadIdcardSdate">
          <el-date-picker
            v-model="ruleForm.leadIdcardSdate"
            value-format="yyyy-MM-dd"
            size="small"
            type="date"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="身份证结束日期" prop="leadIdcardEdate">
          <el-date-picker
            v-model="ruleForm.leadIdcardEdate"
            value-format="yyyy-MM-dd"
            size="small"
            type="date"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item class="overallWidth" label="身份证正反面" prop="leadIdcard">
          <span class="tip">提示：请上传身份证照片或扫描件；支持JPG、JPEG、PNG格式，大小不超过10M</span>
          <div class="flexBox">
            <ImageUpload
              class="interval"
              :explain="'上传身份证人像面'"
              :can-only-pictures="true"
              id-card-front="facade"
              :edit-img-url="ruleForm.leadIdcardFront"
              @getImgUrl="getImgUrl2"
            />
            <ImageUpload
              :can-only-pictures="true"
              id-card-front="reverse"
              :explain="'上传身份证国徽面'"
              :edit-img-url="ruleForm.leadIdcardBack"
              @getImgUrl="getImgUrl3"
            />
          </div>
        </el-form-item>
        <h3 class="page-subtitle-shade">
          管理员账号
        </h3>
        <el-form-item label="管理员账户" prop="userCode">
          <el-input
            v-model="ruleForm.userCode"
            size="small"
            :disabled="ruleForm.cmpCode ? true : false"
            placeholder="请输入管理员账户名"
          />
        </el-form-item>
        <el-form-item label="登录初始密码">
          <el-input v-model="ruleForm.password" size="small" :disabled="true" placeholder="" />
        </el-form-item>
        <el-form-item label="管理员姓名" prop="userName">
          <el-input v-model="ruleForm.userName" size="small" placeholder="请输入用户姓名" />
        </el-form-item>
        <el-form-item label="管理员手机号" prop="phoneNo">
          <el-input v-model="ruleForm.phoneNo" size="small" placeholder="请输入管理员手机号" />
        </el-form-item>
        <el-form-item label="管理员身份证" prop="cardNo">
          <el-input v-model="ruleForm.cardNo" size="small" placeholder="请输入管理员身份证号码" />
        </el-form-item>
        <div class="form-operation">
          <!-- <el-button v-if="ruleForm.authState == '0'" type="info" class="widen-button" size="small" @click="enterpriseCertification">
          企业认证
        </el-button> -->
          <el-button
            v-if="stepsActive!==2"
            size="small"
            :loading="loading"
            type="primary"
            class="widen-button"
            @click="submitForm('ruleForm')"
          >
            提交
          </el-button>
          <!-- <router-link class="cancel" :to="{ path: '/memberManagement/index' }">
          <el-button size="small">
            取消
          </el-button>
        </router-link> -->
        </div>
        <!-- 这些先不要了 -->
        <!-- <el-form-item label="实收资本(万元)" prop="cmpRealFund">
        <el-input-number :precision="2" :controls="false" size="small" placeholder="请输入实收资本(万元)" v-model="ruleForm.cmpRealFund"></el-input-number>
      </el-form-item>
      <el-form-item label="实际控制人" prop="cmpOwner">
        <el-input size="small" placeholder="请输入实际控制人" v-model="ruleForm.cmpOwner"></el-input>
      </el-form-item>
      <el-form-item label="公司状态" prop="businessState">
        <el-input size="small" placeholder="请输入公司状态" v-model="ruleForm.businessState"></el-input>
      </el-form-item>
      <el-form-item label="传真" prop="cmpFax">
        <el-input size="small" type="number" placeholder="请输入传真" v-model="ruleForm.cmpFax"></el-input>
      </el-form-item>
      <el-form-item label="经营范围" prop="cmpBisScope">
        <el-input size="small" type="textarea" maxlength="200" show-word-limit placeholder="请输入经营范围" v-model="ruleForm.cmpBisScope"></el-input>
      </el-form-item>
      <el-form-item label="管理员邮箱">
        <el-input size="small" placeholder="请输入管理员邮箱" v-model="ruleForm.email"></el-input>
      </el-form-item> -->
      </el-form>
    </div>
    <!-- 审核通过了之后 -->
    <div v-else class="auditPageBox">
      <!-- 审核不通过 -->
      <div v-if="ruleForm&&ruleForm.cmpState === '-1'" class="auditNoPass">
        <h3>审核不通过</h3>
        <p>驳回理由：{{ ruleForm.reason }}</p>
      </div>
      <!-- 审核通过 -->
      <div v-if="ruleForm&&ruleForm.authState==='0'" class="auditPass">
        <!-- <h3>审核通过</h3> -->
        <p>
          <span @click="enterpriseCertification">
            前往认证
          </span> 完成认证后可进行合同签署、电子签章等权益
        </p>
      </div>
      <el-descriptions class="margin-top" title="基本信息" :column="2" :size="''">
        <el-descriptions-item label="公司编号">
          {{ ruleForm.cmpCode }}<span v-if="ruleForm&&ruleForm.authState==='1'" class="logoButton">已认证</span><span v-else class="logoButton logoButton2">未认证</span>
        </el-descriptions-item>
        <el-descriptions-item label="公司名称">
          {{ ruleForm.cmpName }}
        </el-descriptions-item>
        <el-descriptions-item label="公司简称">
          {{ ruleForm.cmpShortName }}
        </el-descriptions-item>
        <el-descriptions-item label="授权系统">
          {{ ruleForm.cmpRole6 }}
        </el-descriptions-item>
        <el-descriptions-item label="注册资本(万元)">
          {{ ruleForm.cmpRegFund }}
        </el-descriptions-item>
        <el-descriptions-item label="注册地址">
          {{ ruleForm.cmpAddress }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" title="营业执照信息" :column="2" :size="''">
        <el-descriptions-item label="统一社会信用代码">
          {{ ruleForm.cmpUnicode }}
        </el-descriptions-item>
        <el-descriptions-item label="成立日期">
          {{ ruleForm.cmpDate }}
        </el-descriptions-item>
        <el-descriptions-item label="营业期限截止日">
          {{ ruleForm.cmpCloseDate || '长期' }}
        </el-descriptions-item>
        <el-descriptions-item label="登记机关">
          {{ ruleForm.cmpRegOrg }}
        </el-descriptions-item>
        <el-descriptions-item :span="2" label="公司类型">
          {{ ruleForm.cmpType }}
        </el-descriptions-item>
        <el-descriptions-item :span="2" label="营业执照电子版">
          <div class="electronicMaterial">
            <img
              :src="$fileUrl + ruleForm.businessLicense"
              :onerror="$errImage"
              alt="电子资料"
              @click="$refs.picturePresentation.dialogImageVisible = true; imgUrl = ruleForm.businessLicense"
            >
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" title="法人信息" :column="2" :size="''">
        <el-descriptions-item label="法人姓名">
          {{ ruleForm.leadName }}
        </el-descriptions-item>
        <el-descriptions-item label="法人身份证号">
          {{ ruleForm.leadPaperid }}
        </el-descriptions-item>
        <el-descriptions-item label="身份证有效期开始日">
          {{ ruleForm.leadIdcardSdate }}
        </el-descriptions-item>
        <el-descriptions-item label="身份证有效期截止日">
          {{ ruleForm.leadIdcardEdate }}
        </el-descriptions-item>
        <el-descriptions-item :span="2" label="法人身份证">
          <div class="electronicMaterial">
            <img
              :src="$fileUrl + ruleForm.leadIdcardFront"
              :onerror="$errImage"
              alt="正面"
              @click="$refs.picturePresentation.dialogImageVisible = true; imgUrl = ruleForm.leadIdcardFront"
            >
            <img
              :src="$fileUrl + ruleForm.leadIdcardBack"
              :onerror="$errImage"
              alt="反面"
              @click="$refs.picturePresentation.dialogImageVisible = true; imgUrl = ruleForm.leadIdcardBack"
            >
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" title="管理员账号" :column="2" :size="''">
        <el-descriptions-item label="管理员账号">
          {{ ruleForm.userCode }}
        </el-descriptions-item>
        <el-descriptions-item label="管理员姓名">
          {{ ruleForm.userName }}
        </el-descriptions-item>
        <el-descriptions-item label="管理员手机号">
          {{ ruleForm.phoneNo }}
        </el-descriptions-item>
        <el-descriptions-item label="管理员身份证">
          {{ ruleForm.cardNo }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="form-operation">
        <el-button size="small" type="primary" class="widen-button" @click="ruleForm.cmpState=162">
          重新编辑
        </el-button>
      </div>
    </div>
    <PicturePresentation ref="picturePresentation" :img-url="imgUrl" />
  </div>
</template>

<script>
import ImageUpload from '@/components/ImageUpload'
import { cmpBaseinfoAddAndEdit, cmpBaseinfoDetail } from '@/http/agentApi/login'
import { cmpBaseinfoAuth } from '@/http/agentApi/enterpriseInformation'
import { formPattern, rules } from '@/unit/matchFormRegex'
import PicturePresentation from '@/components/PicturePresentation.vue'
export default {
  components: { ImageUpload, PicturePresentation },
  data() {
    var validateUserCode = (rule, value, callback) => {
      if (Number(value)) {
        callback(new Error('里面必须含有字母'))
      }
      callback()
    }
    const validateLeadIdcard = (rule, value, callback) => {
      if (!this.ruleForm.leadIdcardFront) {
        callback(new Error('请上传身份证正面'))
        return
      }
      if (!this.ruleForm.leadIdcardBack) {
        callback(new Error('请上传身份证反面'))
        return
      }
      callback()
    }
    return {
      ruleForm: {
        businessLicense: '',
        leadIdcardFront: '',
        leadIdcardBack: ''
      },
      stepsActive: 1,
      imgUrl: '',
      loading: false,
      disabled: true,
      userInfo: {},
      authImgSrc: require('@/assets/image/weirenzheng.png'),
      rules: {
        leadName: [{ required: true, message: '请输入', trigger: 'blur' }],
        leadIdcardSdate: [{ required: true, message: '请选择', trigger: 'change' }],
        leadIdcardEdate: [{ required: true, message: '请选择', trigger: 'change' }],
        cmpType: [{ required: true, message: '请输入', trigger: 'blur' }],
        cmpRegOrg: [{ required: true, message: '请输入', trigger: 'blur' }],
        cmpCloseDate: [{ required: true, message: '请选择', trigger: 'change' }],
        cmpDate: [{ required: true, message: '请选择', trigger: 'change' }],
        cmpShortName: [{ required: true, message: '请输入', trigger: 'blur' }],
        leadIdcard: [{ required: true, validator: validateLeadIdcard, trigger: ['input', 'blur', 'change'] }],
        cardNo: [{ required: true, ...formPattern.cardVerification }],
        leadPaperid: [{ required: true, ...formPattern.cardVerification }],
        businessLicense: [{ required: true, message: '请上传', trigger: 'change' }],
        cmpRoleList: [{ required: true, message: '请选择', trigger: 'change' }],
        userName: [{ required: true, message: '请输入用户姓名', trigger: 'blur' }],
        cmpName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        cmpUnicode: [...rules.unicode],
        userCode: [{ required: true, message: '请输入登录账号', trigger: 'blur' },
          { ...formPattern.letterNumberPlaces },
          { validator: validateUserCode, trigger: 'blur' },
          { min: 5, max: 25, message: '长度在 5 到 25 个字符', trigger: 'blur' }],
        phoneNo: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { ...formPattern.tel }
        ]
      }
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'userInfoCust' : 'userInfoAgent'}`)) || {}
    if (this.userInfo && this.userInfo.cmpState === '01') this.disabled = false
    if (this.userInfo && this.userInfo.cmpId) {
      cmpBaseinfoDetail(this.userInfo.cmpId, res => {
        this.ruleForm = { ...res.data }
        if (this.ruleForm.cmpState === '02' || this.ruleForm.cmpState === '04') this.stepsActive = 2
        this.ruleForm.password = '123456'
        this.authImgSrc = this.ruleForm.authState === '0' ? require('@/assets/image/weirenzheng.png') : require('@/assets/image/yirenzheng.png')
        this.ruleForm.registerSource = '01'
        if (this.ruleForm.cmpRoleList && this.ruleForm.cmpRoleList.length > 0) {
          const enterpriseRole = this.$store.getters.getDictionaryItem('SYS_TYPE')
          this.ruleForm.cmpRole6 = ''
          enterpriseRole.forEach(val => {
            if (this.ruleForm.cmpRoleList.includes(val.dictId)) this.ruleForm.cmpRole6 += val.dictName + ' '
          })
        }
      })
      return
    }
    this.$router.push({ path: '/login' })
  },
  methods: {
    // 企业认证
    enterpriseCertification() {
      cmpBaseinfoAuth({ cmpCode: this.userInfo.cmpId }, res => {
        window.open(res.data)
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          cmpBaseinfoAddAndEdit(this.ruleForm, () => {
            this.$message.success('提交成功！审核过程中将无法操作系统')
            this.$router.push({ path: '/login' })
          }, () => {
            this.loading = false
          })
        }
      })
    },
    // 获取图片
    getImgUrl1(value) {
      this.ruleForm.businessLicense = value
      this.validateField('businessLicense')
    },
    getImgUrl2(value) {
      this.ruleForm.leadIdcardFront = value
      this.validateField('leadIdcard')
    },
    getImgUrl3(value) {
      this.ruleForm.leadIdcardBack = value
      this.validateField('leadIdcard')
    },
    // 表单部分字段校验
    validateField(field) {
      this.$refs.ruleForm.validateField(field)
    }
  }
}
</script>

<style lang="scss" scoped>
.auditPageBox {
  .form-operation{
    margin-top: 24px;
  }
  .logoButton{
    height: 21px;
    line-height: 19px;
    width: 52px;
    font-size: 12px;
    color: $mainBlue;
    border: 1px solid $mainBlue;
    box-sizing: border-box;
    border-radius: 2px;
    text-align: center;
    margin-left: 12px;
    display: inline-block;
  }
  .logoButton2{
    color: #333;
    border: 1px solid #D4D1CF;
  }
  .el-descriptions {
    margin-top: 22px;

    /deep/.el-descriptions__body {
      margin-left: 48px;

      .el-descriptions-item__label {
        color: $assistColor7;
      }
    }
  }

  .electronicMaterial {
    width: 100%;
    min-width: 240px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    margin-right: 32px;
  }

  .electronicMaterial img {
    display: inline-block;
    margin-right: 12px;
    height: 120px;
    width: 180px;
    border-radius: 2px;
  }

  .electronicMaterial a {
    color: rgb(26, 7, 136);
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    width: 48px;
  }

  .auditPass {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 863px;
    height: 32px;
    padding: 10px 20px;
    background: linear-gradient(240deg, #fff 0%, #F8FFF5 100%);
    font-size: 12px;

    h3 {
      color: #009E2D;
      font-weight: 800;
    }

    p {
      display: flex;
      align-items: center;
      color: $assistColor7;

      span {
        display: block;
        color: $mainBlue;
        border: 1px solid $mainBorderColor;
        line-height: 24px;
        height: 26px;
        text-align: center;
        width: 68px;
        margin-right: 7px;
        box-sizing: border-box;
        border-radius: 2px;
        cursor: pointer;
        transition: all .5s;
      }
      span:hover{
        background-color: $mainBlue;
        color: #fff;
        border: 1px solid $mainBlue;
      }
    }
  }

  .auditNoPass {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 863px;
    height: 40px;
    padding: 10px 20px;
    background: linear-gradient(240deg, #fff 0%, #FFF5F6 100%);
    font-size: 12px;

    h3 {
      color: $assistColor5;
      font-weight: 800;
    }

    p {
      color: $assistColor7;
    }
  }
}

.informationNotAdded {
  /deep/.el-form-item{
    margin-left: 48px;
  }
  /deep/.overallWidth {
    width: 100% !important;

    .flexBox {
      display: flex;

      .interval {
        margin-right: 32px;
      }
    }

    .tip {
      color: #93959A;
      font-size: 12px;
    }
  }

  .informationFlow {
    font-size: 14px;
    color: #93959A;

    .el-steps {
      width: 824px;
      margin: 46px 0 6px 0;

      /deep/.is-finish .is-text {
        height: 50px;
        width: 50px;
        color: #fff;
        background-color: $mainBlue;
        border: 2px solid $mainBlue;
        font-size: 18px;

        .el-step__icon-inner {
          font-weight: 500 !important;
        }
      }

      /deep/.is-horizontal .el-step__line {
        top: 25px;
        left: 61%;
        height: 1px;
        background-color: $assistColor8;
        width: 212px;
      }

      /deep/.el-step__main .el-step__title,/deep/.el-step__main .is-wait,/deep/.el-step__main .is-process{
        color: $mainFontColor;
        font-weight: 500;
        font-size: 12px;
        line-height: 1;
        padding-top: 12px;
      }
      /deep/.el-step__main .is-finish{
        color: $mainBlue;
      }

      /deep/.el-step__main .is-finish {
        color: $mainBlue;
      }

      /deep/.is-process .is-text,
      /deep/.is-wait .is-text {
        height: 50px;
        width: 50px;
        color: $mainFontColor;
        font-size: 18px;
        background-color: $assistColor8;
        border: 1px solid $assistColor8;

        .el-step__icon-inner {
          font-weight: 500 !important;
        }
      }
    }

    .tip {
      display: flex;
      align-items: center;
      color: $mainFontColor;
      font-size: 12px;
      padding-left: 18px;

      img{
        height: 25px;
        width: 25px;
        margin-right: 7px;
      }
    }

  }

  .enterprise_certification {
    float: left;
    width: 100%;
    text-align: center;
  }

  .basicInformation {
    position: relative;

    .authImgSrc {
      position: absolute;
      height: 120px;
      width: 120px;
      top: 20px;
      right: 120px;
      z-index: 12;
    }
  }

  .ruleForm {
    .cancel {
      margin-left: 12px;
    }
  }
}
</style>
