<template>
  <div class="picturePresentationPageBox">
    <el-dialog :visible.sync="dialogImageVisible" :close-on-click-modal="false">
      <img :src="$fileUrl + imgUrl" alt="文件图">
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    imgUrl: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      dialogImageVisible: false
    }
  }
}
</script>

<style lang="scss">
.picturePresentationPageBox {
 .el-dialog {
    min-width: 1054px;
    min-height: 605px;
    overflow: hidden;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    .el-dialog__body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
    .el-dialog__headerbtn {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: rgba(51, 51, 51, 0.79);
      border-radius: 0px 4px 0px 4px;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      line-height: 30px;
    }

    .el-dialog__close {
      color: #fff;
    }

    img {
      max-width: 100%;
    }
  }
}
</style>
