const limitedFunction = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写'))
    return
  }
  if (Number(value) > 100) {
    callback(new Error('不能超过100'))
    return
  }
  if (Number(value) === 0) {
    callback(new Error('不能为0'))
    return
  }
  callback()
}
const limitedFunction2 = (rule, value, callback) => {
  if (!value && value !== 0) {
    callback(new Error('请填写'))
    return
  }
  if (Number(value) > 100) {
    callback(new Error('不能超过100'))
    return
  }
  if (Number(value) < 0) {
    callback(new Error('不能小于0'))
    return
  }
  callback()
}
// 限制不能为0
const cannotZero = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写'))
    return
  }
  if (Number(value) === 0) {
    callback(new Error('不能为0'))
    return
  }
  const arr = value.toString().split('.')
  if (arr[0].length > 8) {
    callback(new Error('不能超过八位数'))
    return
  }
  callback()
}
// 上传
var uploadFile = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请上传'))
    return
  }
  callback()
}
// 验证数据的数组不能为空
const notEmpty = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请选择'))
    return
  }
  if (value && value.length === 0) {
    callback(new Error('请选择'))
    return
  }
  if (!value[0]) {
    callback(new Error('请选择'))
    return
  }
  callback()
}
// 身份证号码
const cardVerification = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写'))
    return
  }
  const cardReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
  if (value && !cardReg.test(value)) {
    callback(new Error('身份证号码不正确'))
    return
  }
  callback()
}
// 统一社会信用代码
const unifySocialCreditCodes = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写'))
    return
  }
  const cardReg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/
  if (value && !cardReg.test(value)) {
    callback(new Error('请输入正确的统一社会信用代码'))
    return
  }
  callback()
}
// 车牌号码
const carNo = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写'))
    return
  }
  const cardReg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/
  const cardReg1 = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DABCEFGHJK]$)|([DABCEFGHJK][A-HJ-NP-Z0-9][0-9]{4}$))/
  const cardReg2 = /^[京津沪渝冀豫云辽⿊湘皖鲁新苏浙赣鄂桂⽢晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/
  if (value && (!cardReg.test(value) && !cardReg1.test(value) && !cardReg2.test(value))) {
    callback(new Error('请输入正确的车牌号码'))
    return
  }
  callback()
}
const contactWay = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写'))
    return
  }
  const cardReg = /^(0|86|17951)?(13[0-9]|14[5-8]|15[012356789]|16[567]|17[01345678]|18[0-9]|19[0-9])[0-9]{8}$/
  const cardReg1 = /^(([0-9]{3,4}[-])?[0-9]{7,8}$)/
  if (value && (!cardReg.test(value) && !cardReg1.test(value))) {
    callback(new Error('请输入正确的联系方式'))
    return
  }
  callback()
}
// // 许多的联系方式 25字符，仅支持数字、中横线、星号、小括号、加号
const contactInformation = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写'))
    return
  }
  const cardReg = /^[0-9-\(\)\*\+]{4,25}$/
  if (value && !cardReg.test(value)) {
    callback(new Error('仅支持数字、中横线、星号、小括号、加号,4-25位'))
    return
  }
  callback()
}
// 非负数
function funNonnegativeNumber(value) {
  var reg = /^\+?\d*((\.\d+)|\.)?$/
  return reg.test(value)
}
// 小数点后两位
function funTwoDecimalPlaces(value) {
  var reg = /^[+-]?\d+(\.\d{0,2})?$/
  return reg.test(value)
}
// 小数点后三位
function funThreeDecimalPlaces(value) {
  var reg = /^[+-]?\d+(\.\d{0,3})?$/
  return reg.test(value)
}

// 手机号码
const formPattern = {
  // 车牌号码
  carNo: {
    pattern: /^[京津沪渝冀豫云辽⿊湘皖鲁新苏浙赣鄂桂⽢晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/,
    message: '请输入正确的车牌号'
  },
  // 手机号
  tel: {
    pattern:
      /^(0|86|17951)?(13[0-9]|14[5-8]|15[012356789]|16[567]|17[01345678]|18[0-9]|19[0-9])[0-9]{8}$/,
    message: '请输入正确联系方式',
    trigger: ['input', 'blur', 'change']
  },
  // 字母和数字
  letterNumberPlaces: {
    pattern: /^[a-zA-Z\d]+$/,
    message: '请输入字母或数字'
  },
  // 不能有中文的
  noChinese: {
    pattern: /.*[\u4e00-\u9fa5]+.*$/,
    message: '请输入不能含中文的字符'
  },
  // 只能是中文的
  allChinese: {
    pattern: /^[\u4E00-\u9FA5]+$/,
    message: '请输入中文'
  },
  // 小数点后两位
  twoDecimalPlaces: {
    pattern: /^[+-]?\d+(\.\d{0,2})?$/,
    message: '小数点后最多两位数'
  },
  // 小数点后两位
  threeDecimalPlaces: {
    pattern: /^[+-]?\d+(\.\d{0,3})?$/,
    message: '小数点后最多三位数'
  },
  // 身份证号码
  cardVerification: { validator: cardVerification, trigger: ['input', 'blur', 'change'] },
  // 许多的联系方式 25字符，仅支持数字、中横线、星号、小括号、加号
  contactInformation: { validator: contactInformation, trigger: ['input', 'blur', 'change'] },
  // 联系方式包括座机
  contactWay: { validator: contactWay, trigger: ['input', 'blur', 'change'] },
  // 正整数
  number: {
    pattern: /^([1-9]\d*)$/,
    message: '请输入正整数'
  },
  // 非负数
  nonnegativeNumber: {
    pattern: /^\+?\d*(\.\d+)?$/,
    message: '请输入非负数'
  },
  // 座机
  studioCameraPhone: {
    pattern: /^[0-9-*()+]+$/,
    message: '请输入数字、中横线、星号、小括号、加号'
  }
}
const rules = {
  // 许多的联系方式 25字符，仅支持数字、中横线、星号、小括号、加号
  contactInformation: [{ required: true, validator: contactInformation, trigger: ['input', 'blur', 'change'] }],
  // 验证车牌号码
  carNo: [{ required: true, validator: carNo, trigger: ['input', 'blur', 'change'] }],
  // 数组不能为空
  notEmpty: [{ required: true, validator: notEmpty, trigger: ['input', 'blur', 'change'] }],
  // 身份证验证
  cardVerification: [{ required: true, validator: cardVerification, trigger: ['input', 'blur', 'change'] }],
  // 输入框限制50字
  name: [
    { required: true, message: '请输入', trigger: ['blur', 'change'] },
    { min: 1, max: 50, message: '不超过50个字符', trigger: 'blur' }
  ],
  // 车牌号验证
  // 选择日期
  date: [
    { required: true, message: '请选择日期', trigger: 'change' }
  ],
  // 输入数字不能大于100  小数为两位
  limitedNum: [{ required: true, validator: limitedFunction, trigger: ['blur', 'change'] }, { ...formPattern.twoDecimalPlaces }, { ...formPattern.nonnegativeNumber }],
  // 输入数字不能大于100  小数为三位
  limitedThreeNum: [{ required: true, validator: limitedFunction, trigger: ['blur', 'change'] }, { ...formPattern.threeDecimalPlaces }, { ...formPattern.nonnegativeNumber }],
  // 输入数字不能超过8位数
  price: [
    { required: true, message: '请输入', trigger: 'blur' },
    { validator: cannotZero, trigger: ['blur', 'change'] },
    // { min: 1, max: 10, message: '不得超过八位数', trigger: 'blur' },
    { ...formPattern.twoDecimalPlaces },
    { ...formPattern.nonnegativeNumber }
  ],
  // 输入天数不能超过100  但是不能为0
  days: [
    { required: true, message: '请输入天数', trigger: 'blur' },
    { validator: limitedFunction, trigger: 'blur' },
    { ...formPattern.number }
  ],
  // 输入天数不能超过100  可以为0
  days2: [
    { required: true, message: '请输入天数', trigger: 'blur' },
    { validator: limitedFunction2, trigger: 'blur' }
  ],
  unicode: [
    { required: true, message: '请输入统一社会信用代码', trigger: 'blur' },
    { validator: unifySocialCreditCodes, trigger: ['input', 'blur', 'change'] }
  ],
  // 选择地址
  allAddress: [{ required: true, message: '请选择地址', trigger: 'change' }],
  // 普通输入信息
  message: [{ required: true, message: '请输入', trigger: ['blur', 'change'] }],
  // 电话号码
  phone: [
    { required: true, message: '请输入联系电话', trigger: 'blur' },
    { ...formPattern.tel }
  ],
  // 选择类型
  selectiveType: [{ required: true, message: '请选择', trigger: ['input', 'blur', 'change'] }],
  uploadFile: [
    { required: true, validator: uploadFile, trigger: ['input', 'blur', 'change'] }
  ]
}
export { formPattern, rules, funNonnegativeNumber, funTwoDecimalPlaces, funThreeDecimalPlaces }
